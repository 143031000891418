(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset/components/hooks/assets/javascripts/use-selfservice-back-button.js') >= 0) return;  svs.modules.push('/modules/oddset/components/hooks/assets/javascripts/use-selfservice-back-button.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;

const {
  useEffect,
  useContext
} = React;
const {
  matchPath
} = ReactRouterDOM;
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const {
  NavContext
} = svs.oddset.components.nav_context;
const isBasePath = (route, activePathname) => Boolean(matchPath(activePathname, {
  path: ["/".concat(route.path), "/".concat(route.sbPath)],
  exact: true
}));
const activeNavigateBackElement = "\n<button\n  class=\"btn btn-transparent-inverted focus-inverted margin-right-1 backbutton\"\n  type=\"button\"\n  onclick=\"window.history.back()\"\n>\n  <span class=\"btn-link-text\">\n    <i class=\"icon icon-default icon-300 icon-selfservice-arrow-left\"></i>\n    Bak\xE5t\n  </span>\n</button>";
const disabledNavigateBackElement = "\n<button\n  disabled\n  class=\"btn btn-transparent-inverted focus-inverted margin-right-1 backbutton\"\n  type=\"button\"\n  onclick=\"window.history.back()\"\n>\n  <span class=\"btn-link-text\">\n    <i class=\"icon icon-default icon-300 icon-selfservice-arrow-left\"></i>\n    Bak\xE5t\n  </span>\n</button>";
const useSelfserviceBackButton = () => {
  const {
    activePathname,
    currentRoute
  } = useContext(NavContext);
  useEffect(() => {
    if (isSelfService && activePathname) {
      const selfserviceNavBtn = document.querySelector('.selfservice-nav-btn');
      const selfserviceNavContainerSelector = document.querySelector('.oddset-ss-nav-container');
      const selfserviceNavBtnHTML = selfserviceNavBtn.innerHTML;
      const toStart = "<a href=\"/\" class=\"selfservice-nav-btn\">".concat(selfserviceNavBtnHTML, "</a>");
      const navigateBackElement = isBasePath(currentRoute, activePathname) ? disabledNavigateBackElement : activeNavigateBackElement;
      const selfserviceNavContainer = "<div class=\"oddset-ss-nav-container\">".concat(navigateBackElement).concat(toStart, "</div>");
      if (selfserviceNavContainerSelector) {
        selfserviceNavContainerSelector.innerHTML = selfserviceNavContainer;
      } else {
        selfserviceNavBtn.outerHTML = selfserviceNavContainer;
      }
    }
  }, [activePathname, currentRoute]);
};
setGlobal('svs.oddset.components.hooks.useSelfserviceBackButton', useSelfserviceBackButton);

 })(window);